<template>
  <div>
    <el-input
      v-if="isTextarea"
      class="tbb-input"
      :value="value"
      type="textarea"
      :disabled="disabled"
      :placeholder="$t(placeholder)"
      @input="$emit('input', $event)"
    />

    <el-input
      v-else
      class="tbb-input"
      :value="value"
      :type="inputType ? 'text' : 'password'"
      :placeholder="$t(placeholder)"
      :disabled="disabled"
      @input="$emit('input', $event)"
      @keyup.enter.native="handelEnter"
    >
      <svg-icon
        v-if="value && !inputType && showEye"
        slot="suffix"
        icon-class="close-eyes"
        class="tbb-input-icon"
        @click="inputType = !inputType"
      />
      <svg-icon
        v-if="value && inputType && showEye"
        slot="suffix"
        icon-class="eyes"
        class="tbb-input-icon"
        @click="inputType = !inputType"
      />
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'TbbInput',
  props: {
    value: [String, Number,Boolean],
    placeholder: {
      type: String,
      default: 'placeholder.please_enter'
    },
    // svg 文件名
    iconClass: {
      type: String,
      default: ''
    },
    // 是否为密码
    showPas: {
      type: Boolean,
      default: true
    },
    // 是否显示眼睛
    showEye: {
      type: Boolean,
      default: false
    },
    // 是否多文本
    isTextarea: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    let that = this
    return {
      inputType: that.showPas
    }
  },
  methods: {
    handelEnter() {
      this.$emit('enter', this.value)
    }
  }
}
</script>

<style lang="scss">
.tbb-input {
  .el-input__inner,
  .el-textarea__inner {
    background-color: #3e3f42 !important;
    font-family: HARMONYOS_SANS_BLACK;
    border: none;
    color: #fff;
    &::placeholder {
      color: #999999;
      font-size: 14px;
      font-family: HARMONYOS_SANS_REGULAR;
    }
  }
  .el-input__suffix-inner {
    line-height: 50px;
  }
}
.tbb-input-icon {
  font-size: 20px;
  cursor: pointer;
}
</style>
