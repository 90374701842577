const hearderPathIsBtn = [
  'detail',
  'alarm',
  'alocation',
  'report',
  'set',
  'subdetail',
  'airconditioner'
]
const footerPathIsBtn = [
  '/home',
  '/vehicles/subdetail/page',
  '/home/detail/page',
  '/share/detail/page',
  '/vehicles/airconditioner/page'
]

const asideMenuPath = [
  {
    childPath: 'alarm',
    parentPath: '/home'
  },
  {
    childPath: 'detail',
    parentPath: '/home'
  },
  {
    childPath: 'report',
    parentPath: '/data/report'
  },
  {
    childPath: 'alocation',
    parentPath: '/users/role'
  },
  {
    childPath: 'subdetail',
    parentPath: '/vehicles/list'
  },
  {
    childPath: 'airconditioner',
    parentPath: '/vehicles/list'
  }
]

const collectAsideMenuPath = [
  {
    childPath: 'alarm',
    parentPath: 'home'
  },
  {
    childPath: 'detail',
    parentPath: 'home'
  },
  {
    childPath: 'report',
    parentPath: 'report'
  },
  {
    childPath: 'alocation',
    parentPath: 'role'
  },
  {
    childPath: 'subdetail',
    parentPath: 'list'
  },
  {
    childPath: 'airconditioner',
    parentPath: 'list'
  }
]

export {
  hearderPathIsBtn,
  asideMenuPath,
  collectAsideMenuPath,
  footerPathIsBtn
}
