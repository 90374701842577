// 查询列表数据
let listCmd = {
  cmd: 16
}

// 实时订阅
let detailSubscribeCmd = {
  cmd: 32,
  data: {}
}

// 移除订阅

let detailRemoveSubscribeCmd = {
  cmd: 48,
  data: {}
}

// 心跳
let heartBeatCmd = {
  cmd: 5
}

// 系统配置文件恢复出厂设置

let resetConfigCmd = {
  cmd: 86,
  data: {
    setList: [
      {
        deviceTypeKey: 'system',
        deviceId: 0,
        setMap: {
          restore_fac: '1'
        }
      }
    ]
  }
}

// 实时数据查询请求 systemType 0000  rego 旧（64）  其他新 hub（66）

let detailSubscribeCmdData = {
  cmd: 66,
  data: {
    queryMap: {
      system: [
        {
          deviceId: 0,
          realKeys: []
        }
      ],
      // 电池
      bms: [
        {
          deviceId: 0,
          realKeys: []
        }
      ],
      // alt 行车
      dcdc: [
        {
          deviceId: 0,
          realKeys: []
        }
        // {
        //   deviceId: 255,
        //   realKeys: []
        // }
      ],
      // solar 太阳能
      mppt: [
        {
          deviceId: 0,
          realKeys: []
        },
        {
          deviceId: 1,
          realKeys: []
        }
      ],
      // 逆变器
      inv: [
        {
          deviceId: 0,
          realKeys: []
        }
      ],

      dtu: [
        {
          deviceId: 0,
          realKeys: []
        }
      ],
      watertank: [
        {
          deviceId: 0,
          realKeys: []
        },
        {
          deviceId: 1,
          realKeys: []
        },
        {
          deviceId: 2,
          realKeys: []
        },
        {
          deviceId: 3,
          realKeys: []
        }
      ]
    }
  }
}

// 实时告警记录
let alarmRecordCmd = {
  cmd: 128,
  data: {}
}

// 查询系统运行状态命令

let detailStatusCmd = {
  cmd: 18,
  data: {}
}
export {
  listCmd,
  detailSubscribeCmd,
  detailRemoveSubscribeCmd,
  detailSubscribeCmdData,
  heartBeatCmd,
  alarmRecordCmd,
  detailStatusCmd,
  resetConfigCmd
}
