<template>
  <div class="operation-dialog">
    <el-dialog
      :visible.sync="show"
      :width="width"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="showClose"
      :before-close="handleCancel"
    >
      <div slot="title">
        <p class="base-dialog-title">{{ $t(operaInfo.title) }}</p>
      </div>
      <slot name="content">
        <div class="operation-content font-16" v-if="showContent">
          {{ $t(decInfo) }}
          <span v-if="operaInfo.operaName">“{{ operaInfo.operaName }} ”?</span>
        </div>
        <div class="other-content font-16" v-else>
          {{ $t(operaInfo.otherDec) }}
        </div>
      </slot>
      <div slot="footer" class="tbb-dialog-footer">
        <el-button @click="handleCancel" v-if="cancel">
          {{ $t('button.cancel') }}
        </el-button>
        <el-button
          v-if="confirm"
          class="confirm-danger-btn"
          type="danger"
          :loading="setDialogConfirmBtn"
          @click="handelConfirm"
        >
          {{ $t('button.confirm') }}
        </el-button>

        <el-button
          v-else
          class="base-dialog-confirm"
          type="lightblue"
          @click="handelLightBlueConfirm"
        >
          {{ $t('button.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OperationDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '500px'
    },
    decInfo: {
      type: String,
      default: 'dialog.delete.delete_dec'
    },
    operaInfo: {
      type: Object,
      default: () => {}
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showContent: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['setDialogConfirmBtn'])
  },
  methods: {
    handleCancel() {
      this.$emit('cancel', false)
    },
    handelConfirm() {
      this.$store.dispatch('setDialogConfirmBtn', true)

      this.$emit('confirm')
    },
    handelLightBlueConfirm() {
      this.$emit('blueConfirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-dialog {
  .operation-content {
    margin: 30px 0 60px 0;
  }
  .other-content {
    margin: 30px 0 0;
  }
}
.el-button {
  padding: 0 20px;
}
.confirm-danger-btn,
.el-button--danger {
  width: 150px;
  height: 45px;

  font-family: HARMONYOS_SANS_BLACK;
}

.base-dialog-confirm,
.el-button--lightblue {
  width: 150px;
  height: 45px;
  font-family: HARMONYOS_SANS_BLACK;
}

.el-button--default {
  height: 43px;
}
</style>
