<template>
  <div>
    <OperationDialog
      :show="showDialog"
      :opera-info="operaInfo"
      :show-content="false"
      :cancel="false"
      :show-close="false"
      @confirm="handelConfirm"
    />
  </div>
</template>

<script>
import OperationDialog from '@/components/TbbDialog/OperationDialog.vue'

export default {
  name: 'PlugDialog',
  components: {
    OperationDialog
  },
  data() {
    return {
      showDialog: false,
      operaInfo: {
        title: 'dialog.attention',
        otherDec: 'error.error_login_token'
      }
    }
  },
  created() {
    this.getSocketCmd()
  },
  methods: {
    getSocketCmd() {
      let cmdId = sessionStorage.getItem('socket-cmd')
      if (cmdId) {
        if (cmdId == 1001) {
          this.operaInfo.otherDec = 'error.error_status_changed'
        } else if (cmdId == 1002) {
          this.operaInfo.otherDec = 'error.error_role_changed'
        }
        this.showDialog = true
      } else {
        this.operaInfo.otherDec = 'error.error_login_token'
        this.showDialog = true
      }
    },
    handelConfirm() {
      this.showDialog = false
      this.$store.dispatch('LogOut').then(() => {
        location.href = '/login'
        this.$store.dispatch('setDialogConfirmBtn', false)
      })
    }
  }
}
</script>
