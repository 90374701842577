export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        // 封装的$refs动态获取方法(Table返回顶部)
        getRefsDynamic(refsName) {
          document.getElementsByClassName(
            'el-table__body-wrapper'
          )[0].scrollTop = 0

          this.$refs[refsName].scrollIntoView({ behavior: 'smooth' })
        }
      }
    })
  }
}
